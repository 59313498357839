import { Typography } from 'antd'
import { t } from 'i18next'
import { useMemo, useState } from 'react'
import styled from 'styled-components'

import {
  useGetDashboardChannelQuery,
  useGetDashboardListOrderQuery,
  useGetDashboardOverviewQuery,
  useGetTeacherCourseMiniDashboardQuery,
} from '@api'
import { CURRENT_YEAR } from '@configs'
import { themes } from '@theme'
import { DashboardChannels } from 'src/pages/dashboard/channels'
import { ListCourseOrderIncome } from 'src/pages/dashboard/list-course'
import { DashboardOverview } from 'src/pages/dashboard/overview'
import { EOverviewDateFilter } from 'src/pages/dashboard/overview/config'
import {
  DashboardProvider,
  useDashboardContext,
} from 'src/pages/dashboard/provider'

const Dashboard = () => {
  const { filter } = useDashboardContext()

  const [filterOverview, setFilterOverview] = useState<{
    filterBy: EOverviewDateFilter
    startDate?: string
    endDate?: string
  }>({ filterBy: EOverviewDateFilter.LAST_SIX_MONTHS })
  const [filterChannel, setFilterChannel] = useState<string | number>(
    CURRENT_YEAR
  )

  const courseOrdersQueryParams = useMemo(() => {
    const { currentPage, courses, ...rest } = filter

    return {
      ...rest,
      ...(!!courses?.length && {
        courseIds: courses?.join(','),
      }),
      page: currentPage ?? 1,
      limit: 5,
    }
  }, [filter])

  const { data: overviewData, isFetching: isFetchingOverviewData } =
    useGetDashboardOverviewQuery({
      queryParams: {
        ...filterOverview,
        limit: 10,
        page: 1,
      },
    })

  const { data: channelsData } = useGetDashboardChannelQuery({
    year: filterChannel,
  })

  const { data: courseOrders, isFetching: dashboardListOrderLoading } =
    useGetDashboardListOrderQuery({
      queryParams: courseOrdersQueryParams,
    })

  const { data: miniDashboardData } = useGetTeacherCourseMiniDashboardQuery()

  const handleFilterOverview = ({
    filterBy,
    startDate,
    endDate,
  }: {
    filterBy: EOverviewDateFilter
    startDate?: string
    endDate?: string
  }) => {
    if (
      [EOverviewDateFilter.CUSTOM].includes(filterBy) &&
      (!startDate || !endDate)
    ) {
      return
    }
    setFilterOverview({
      filterBy,
      ...(startDate && {
        startDate,
      }),
      ...(endDate && {
        endDate,
      }),
    })
  }

  return (
    <>
      <Typography.Title
        style={{
          marginBottom: 8,
        }}
        level={2}
      >
        {t('dashboard:dashboard')}
      </Typography.Title>
      <Typography.Title
        style={{
          marginTop: 0,
          marginBottom: 16,
          color: '#179CB8',
        }}
        level={4}
      >
        {t('dashboard:welcome_to_teach_llc')}
      </Typography.Title>
      <PackageInfoWrapperStyled>
        <div className="gradient-border">
          <div className="content-wrapper">
            <Typography.Title level={2}>
              {t('courseManagement:current_package', {
                string: miniDashboardData?.data?.teacherPackageName,
              })}
            </Typography.Title>
            {!!miniDashboardData?.data?.memberCourseLimit ? (
              <Typography.Text className="mr-4">
                {t('courseManagement:member_course_publish', {
                  num1: miniDashboardData?.data?.memberCoursePublish,
                  num2: miniDashboardData?.data?.memberCourseLimit,
                })}
              </Typography.Text>
            ) : null}
            {!!miniDashboardData?.data?.premiumCourseLimit ? (
              <Typography.Text>
                {t('courseManagement:platium_course_publish', {
                  num1: miniDashboardData?.data?.premiumCoursePublish,
                  num2: miniDashboardData?.data?.premiumCourseLimit,
                })}
              </Typography.Text>
            ) : null}
          </div>
        </div>
      </PackageInfoWrapperStyled>
      <DashboardStyled>
        <DashboardOverview
          onChangeFilterOverview={handleFilterOverview}
          overviewData={overviewData}
          isLoading={isFetchingOverviewData}
        />
        <DashboardChannels
          dashboardChannels={channelsData}
          onChangeFilter={(year) => setFilterChannel(year)}
        />
        <ListCourseOrderIncome
          courseOrders={courseOrders}
          isLoading={dashboardListOrderLoading}
          // isDisabled={!courseOrders?.items?.length}
        />
      </DashboardStyled>
    </>
  )
}

export const DashboardWithContext = () => (
  <DashboardProvider>
    <Dashboard />
  </DashboardProvider>
)

const PackageInfoWrapperStyled = styled.div`
  width: max-content;
  max-width: 100%;

  .gradient-border {
    background-image: linear-gradient(
      45deg,
      ${themes.theme.light.colors.primary},
      ${themes.theme.light.colors.lightBlue}
    );
    border-radius: 16px;
    padding: 8px;

    .content-wrapper {
      background: #fff;
      border-radius: 8px;
      box-shadow: 0 0 15px ${themes.theme.light.colors.primary};
      padding: 16px 24px;
    }
  }
`

const DashboardStyled = styled.div`
  width: 100%;
  padding: 24px 0;
  display: flex;
  flex-direction: column;
  gap: 24px;
`
