import { zodResolver } from '@hookform/resolvers/zod'
import { Skeleton, Card, message } from 'antd'
import { FormProvider, useForm } from 'react-hook-form'
import { useEffect, useLayoutEffect } from 'react'
import { z } from 'zod'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useQueryClient } from '@tanstack/react-query'

import { Button } from 'src/common'
import { TEducation, TFillInTeacherInformation } from '@interfaces'
import { RootState, useAppDispatch } from '@redux'
import {
  UPLOAD_AVATAR_LOADING_KEY,
  UPLOAD_DEGREE_LOADING_KEY,
} from 'src/pages/auth/sign-up/configs'
import { useMutationUpdateDetailsQuery } from '@api'
import { useGetTeacherApplicationQuery } from '@api'
import { PATH_404, QK_GET_USER_APPLICATION } from '@configs'
import { SignatureSection } from 'src/pages/auth/sign-up/fill-in-teacher-information/signature/SignatureSection'
import {
  personalInformationSchema,
  specificInformationSchema,
} from 'src/pages/auth/sign-up/fill-in-teacher-information/schema'
import { ButtonWrapper } from 'src/pages/auth/sign-up/fill-in-teacher-information/style'
import { PersonalInformation } from 'src/pages/auth/sign-up/fill-in-teacher-information/personal-information'
import { Educations } from 'src/pages/auth/sign-up/fill-in-teacher-information/educations'
import { WorkExperiences } from 'src/pages/auth/sign-up/fill-in-teacher-information/work-experiences'
import { CoursesReference } from 'src/pages/auth/sign-up/fill-in-teacher-information/courses-reference'
import styled from 'styled-components'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'

export const schema = z.object({
  personalInformation: personalInformationSchema,
  specificInformation: specificInformationSchema,
})

const defaultFormValues: TFillInTeacherInformation = {
  personalInformation: {
    firstname: '',
    lastname: '',
    gender: '',
    avatarId: 0,
    dateOfBirth: '',
    phone: '',
    description: '',
    email: '',
    password: '',
  },
  specificInformation: {
    education: [
      {
        institution: '',
        educationLevel: '',
        qualification: '',
        description: '',
        startDate: '',
        endDate: '',
        editable: true,
        isPresent: false,
        degree: {
          file: {
            name: '',
            fileId: 0,
          },
        },
      },
    ],
    workExperience: [
      {
        companyName: '',
        jobTitle: '',
        description: '',
        startDate: '',
        endDate: '',
        editable: true,
        isPresent: false,
      },
    ],
    courseReference: [
      {
        name: '',
        url: '',
        editable: true,
      },
    ],
    teacherSignature: {
      fileId: 0,
      url: '',
      name: '',
    },
  },
}

export const TeacherInfoForm = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const { t } = useTranslation(['profile'])

  const { signUpAccountInfor, loadings, accessToken } = useSelector(
    (state: RootState) => state.auth
  )

  const isUploadAvatarLoading = !!loadings[UPLOAD_AVATAR_LOADING_KEY]
  const isUploadDegreeLoading = !!loadings[UPLOAD_DEGREE_LOADING_KEY]

  const methods = useForm<TFillInTeacherInformation>({
    resolver: zodResolver(schema),
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: defaultFormValues,
  })

  const {
    resetField,
    handleSubmit,
    reset,
    formState: { isDirty },
  } = methods

  const { mutate: updateDetailsMutation, isPending: isUpdateDetailsLoading } =
    useMutationUpdateDetailsQuery({})

  const getTeacherApplicationQuery = useGetTeacherApplicationQuery({
    options: {
      enabled: !!accessToken,
      gcTime: 0,
    },
  })

  const handleSubmitForm = handleSubmit(async (value) => {
    try {
      const payload: TFillInTeacherInformation = {
        ...value,
        specificInformation: {
          ...value?.specificInformation,
          education: value?.specificInformation?.education?.map((item) => {
            if (!item?.degree?.file?.fileId && !item?.degree?.file?.name) {
              const newItem = { ...item }
              delete newItem.degree

              return newItem
            }

            return {
              ...item,
              degree: { ...item?.degree?.file },
            }
          }) as TEducation[],
        },
      }

      await updateDetailsMutation(payload, {
        onSuccess: () => {
          message.success(t('profile:updateTeacherProfileSuccess'))
        },
      })
    } catch (error: any) {
      toast.error(error?.message || 'Something went wrong! Please try again')
    }
  })

  // update personal information fields
  useEffect(() => {
    if (!signUpAccountInfor || !Object.keys(signUpAccountInfor)?.length) return

    resetField('personalInformation', {
      defaultValue: {
        ...defaultFormValues.personalInformation,
        ...signUpAccountInfor,
      },
    })
  }, [signUpAccountInfor, resetField, reset])

  // update specific information fields
  useEffect(() => {
    if (
      getTeacherApplicationQuery?.isError &&
      Number(getTeacherApplicationQuery?.error?.code) === 404
    ) {
      reset()
      navigate(PATH_404)
      return
    }

    if (
      getTeacherApplicationQuery.isSuccess &&
      getTeacherApplicationQuery.data?.data
    ) {
      const { specificInformation, personalInformation } =
        getTeacherApplicationQuery.data?.data

      const transformedSpecificInformation = Object.assign(
        specificInformation || {},
        {
          workExperience: specificInformation?.workExperience?.map(
            (experience) => ({
              ...experience,
              isPresent: experience?.isPresent || false,
              editable: false,
            })
          ),
          courseReference: specificInformation?.courseReference?.map(
            (reference) => ({
              ...reference,
              editable: false,
            })
          ),
          education: specificInformation?.education?.map((education) => ({
            ...education,
            degree: {
              file: {
                fileId: education?.degree?.fileId || 0,
                name: education?.degree?.name || '',
              },
              mediaFile: { ...education?.degree?.file },
            },
            isPresent: education?.isPresent || false,
            editable: false,
          })),
          teacherSignature: {
            ...specificInformation?.teacherSignature,
            editable: !specificInformation?.teacherSignature?.fileId,
            signaturePhoto: {
              ...specificInformation?.teacherSignature?.signaturePhoto,
            },
          },
        }
      )

      const transformedPersonalInformation = Object.assign(
        personalInformation || {},
        {
          avatarId: personalInformation?.avatar?.id,
          editable: false,
        }
      )

      reset({
        ...defaultFormValues,
        personalInformation: {
          ...defaultFormValues.personalInformation,
          ...transformedPersonalInformation,
        },
        specificInformation: {
          ...defaultFormValues.specificInformation,
          ...transformedSpecificInformation,
        },
      })
    }
  }, [
    dispatch,
    getTeacherApplicationQuery.data?.data,
    getTeacherApplicationQuery?.error?.code,
    getTeacherApplicationQuery?.isError,
    getTeacherApplicationQuery.isSuccess,
    navigate,
    queryClient,
    reset,
  ])

  // remove cached data when unmount
  useLayoutEffect(() => {
    return () => {
      queryClient.removeQueries({
        queryKey: [QK_GET_USER_APPLICATION],
        type: 'all',
      })
      reset()
    }
  }, [queryClient, reset])

  const isViewOnly = false

  const isLoadingSubmit =
    isUploadAvatarLoading || isUploadDegreeLoading || isUpdateDetailsLoading

  if (getTeacherApplicationQuery?.isFetching) {
    return <Skeleton paragraph={{ rows: 4 }} className="p-5" />
  }

  return (
    <FormProvider {...methods}>
      <FillInTeacherInformationPageStyled2 className="!border-none flex items-center justify-center w-full !max-w-full !border-0">
        <PersonalInformation isViewMode={isViewOnly} />
        <Educations isViewMode={isViewOnly} canEdit />
        <WorkExperiences isViewMode={isViewOnly} canEdit />
        <CoursesReference isViewMode={isViewOnly} canEdit />
        <SignatureSection isViewMode={isViewOnly} />

        {/* {!getTeacherApplicationQuery?.isSuccess && (
          <CheckBoxStyled className="mt-6">
            <Checkbox
              checked={isAgreePolicy}
              onChange={(e) => {
                setAgreePolicy(e.target.checked)
              }}
            />
            <Typography.Text className="ml-4">
              {t('signUp:bySignUpFirst')}
              <Typography.Text className="!text-main">
                {` ${t('signUp:termAndPrivacy')}`}
              </Typography.Text>
            </Typography.Text>
          </CheckBoxStyled>
        )} */}

        {!isViewOnly && (
          <ButtonWrapper>
            <Button
              htmlType="button"
              type="primary"
              id="blog-form"
              onClick={handleSubmitForm}
              loading={isLoadingSubmit}
              disabled={!isDirty}
            >
              {t('profile:saveChanges')}
            </Button>
          </ButtonWrapper>
        )}
      </FillInTeacherInformationPageStyled2>
    </FormProvider>
  )
}

export const FillInTeacherInformationPageStyled2 = styled(Card)`
  min-width: 70dvw;
  max-width: 70dvw;

  .ant-card-body {
    width: 100%;
    padding: 24px !important;
  }

  .reset-form {
    > div:last-child {
      justify-content: center;
    }
  }
`
