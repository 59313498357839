import { Typography } from 'antd'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { zodResolver } from '@hookform/resolvers/zod'
import { useNavigate } from 'react-router-dom'

import { ISignUpFields, IUserResponseLogin } from '@interfaces'
import { Button, Input } from 'src/common'
import { SignUpGeneralFormSchema } from 'src/pages/auth/sign-up/general-form/config'
import { PATH_LOGIN } from '@configs'
import { LeftOutlined } from '@ant-design/icons'
import { useMutationSignUpQuery } from 'src/api/react-query/hooks/auth/useMutationSignUpQuery'
import { StyledContainer } from 'src/pages/auth/sign-up/style'
import { authActions, useAppDispatch } from '@redux'

interface IProps {
  onSuccess?: () => void
}

export const SignUpGeneralForm = (props: IProps) => {
  const { onSuccess } = props

  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { t } = useTranslation(['common', 'login', 'signUp'])

  const { control, reset, handleSubmit, getValues } = useForm<ISignUpFields>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: {
      firstname: '',
      lastname: '',
      email: '',
      password: '',
    },
    resolver: zodResolver(SignUpGeneralFormSchema),
  })

  const { mutate: signUpMutation, isPending: isSignUpLoading } =
    useMutationSignUpQuery({
      handleSuccess: () => {
        dispatch(
          authActions.setSignUpAccount({
            ...getValues(),
          })
        )
        reset()
        onSuccess && onSuccess()
      },
    })

  const handleSignUp = handleSubmit(async (value) => {
    signUpMutation(value)
  })

  const handleRedirectToLogin = () => {
    reset()
    navigate(PATH_LOGIN)
  }

  return (
    <StyledContainer className="login__section">
      {/* @ts-ignore */}
      <form onSubmit={handleSignUp} className="signUp-form">
        <div className="flex w-full gap-8 items-start mb-6">
          <Controller
            name="firstname"
            control={control}
            render={({ field: { value, onChange }, fieldState: { error } }) => {
              return (
                <Input
                  label={t('signUp:first_name')}
                  placeholder={t('signUp:first_name')}
                  name="firstname"
                  alignment="col"
                  labelClassName="font-semibold"
                  errors={error?.message}
                  value={value}
                  onChange={onChange}
                />
              )
            }}
          />
          <Controller
            name="lastname"
            control={control}
            render={({ field: { value, onChange }, fieldState: { error } }) => {
              return (
                <Input
                  label={t('signUp:last_name')}
                  placeholder={t('signUp:last_name')}
                  name="lastname"
                  alignment="col"
                  labelClassName="font-semibold"
                  errors={error?.message}
                  value={value}
                  onChange={onChange}
                />
              )
            }}
          />
        </div>
        <Controller
          name="email"
          control={control}
          render={({ field: { value, onChange }, fieldState: { error } }) => {
            return (
              <Input
                label={t('login:email')}
                placeholder={t('common:email')}
                name="email"
                alignment="col"
                labelClassName="font-semibold"
                errors={error?.message}
                value={value}
                onChange={onChange}
              />
            )
          }}
        />

        <Controller
          name="password"
          control={control}
          render={({ field: { value, onChange }, fieldState: { error } }) => {
            return (
              <Input
                label={t('login:password')}
                placeholder="Password"
                type="password"
                alignment="col"
                containerClassName={'mt-5'}
                labelClassName="font-semibold"
                haveShowPassIcon
                errors={error?.message}
                value={value}
                onChange={onChange}
              />
            )
          }}
        />

        <div className="button_wrapper_grid">
          <div className="Input__text-error mt-2 text-sm col-span-7 flex justify-center mb-4">
            <Button
              htmlType="submit"
              type="primary"
              size="large"
              className="!w-full !rounded-full"
              style={{ backgroundColor: '#FE763E', borderColor: '#FE763E' }}
              loading={isSignUpLoading}
            >
              {t('common:next')}
            </Button>
          </div>
          <div className="Input__text-error mt-2 text-sm col-span-7 flex justify-center">
            <Typography.Text
              onClick={handleRedirectToLogin}
              className="cursor-pointer"
            >
              <LeftOutlined className="mr-1 mt-1" />
              {t('login:back_to_login')}
            </Typography.Text>
          </div>
        </div>
      </form>
    </StyledContainer>
  )
}
